import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useMonitoringFile } from "@/context/MonitoringFileContext";
import { useTabContext } from "@/context/TabContextProvider";
import { useTourtip } from "@/context/TourtipContext";
import { FILE_PAGE_NAMES, FilePage, filePagesForFile, getFileHref, useCurrentLocationFromHref } from "@/lib/path-utils";
import { capitalizedFileType } from "@/services/files.service";
import TabGroup from "@components/library/Tabs/TabGroup";
import { Tourtip } from "@components/library/Tourtips/Tourtip";
import { AttentionDot } from "@components/library/Tourtips/AttentionDot";
import { classNames } from "@components/library/utils/classNames";
import { getFileTypeFromId } from "@components/library/utils/versions";
import { useEffect, useState } from "react";
import { FILE_PAGES_HIDDEN_FROM_TREE } from "../DirectoryStructure/TreeNodes";

const useNowHasLogsTourtip = () => {
  const { file } = useMonitoringFile();
  const { tipState, trigger, isActive, isDismissed, dismiss } = useTourtip("has-logs-see-logs-tab");
  const [previousLogCount, setPreviousLogCount] = useState<number | null>(0); // TODO: switch to null. 0 only for testing
  const [logCount, setLogCount] = useState<number | null>(null);

  useEffect(() => {
    if (file?.type === "prompt" || file?.type === "evaluator" || file?.type === "tool" || file?.type === "flow") {
      const currentLogCount = file.total_logs_count;
      // Only show the tip when logs go from 0 to >0
      if (previousLogCount === 0 && currentLogCount > 0) {
        trigger();
      }
      setLogCount(currentLogCount);
      setPreviousLogCount(currentLogCount);
    }
  }, [file, trigger]);

  const showTourtip = !!(isActive && logCount && logCount > 0 && file?.type === "prompt");

  const tourtipWrapper = (children: React.ReactNode) => (
    <Tourtip
      title="Logs are viewable here"
      body="Every call of this Prompt (in the UI or API) is viewable in the Logs table"
      active={!!isActive}
      setDismissed={dismiss}
    >
      {children}
    </Tourtip>
  );

  return {
    tourtipWrapper,
    showTourtip,
    isDismissed,
    dismiss,
  };
};

export const FileTabs = () => {
  const { slug } = useActiveOrganization();
  const { file } = useMonitoringFile();
  const currentLocation = useCurrentLocationFromHref();
  const { setLastSelectedTab, lastSelectedTab } = useTabContext();

  // Use state so that we can immediately update the tab when a button is clicked,
  // otherwise it looks unselected until the page renders the new content.
  const [currentTab, setCurrentTab] = useState<FilePage | undefined>(
    currentLocation?.type === "file" && currentLocation.page ? currentLocation.page : undefined,
  );

  useEffect(() => {
    if (currentLocation?.type === "file" && currentLocation.page) {
      setCurrentTab(currentLocation.page);
    }
    // Specific, because if this was just `currentLocation` it triggers lots and the buttons flicker.
  }, [currentLocation?.type === "file" && currentLocation?.page]);

  let availableTabs: FilePage[] | undefined = [];

  if (currentLocation && currentLocation.type !== "directory") {
    // Fallback to the ID as the full file might not be loaded yet. And knowning that its an
    // "evaluator" for example, is still helpful, even if the "llm evaluator" subtype does have
    // different pages available.
    const fileType = file ? file.type : getFileTypeFromId(currentLocation.id);

    const pages = filePagesForFile(file || { type: fileType });
    availableTabs = pages.filter((page) => !FILE_PAGES_HIDDEN_FROM_TREE.includes(page));
  }

  const { tourtipWrapper, showTourtip, isDismissed, dismiss } = useNowHasLogsTourtip();

  const pages = ["dashboard", "editor", "logs", "evaluations"] as const;

  return (
    <div className={classNames("flex grow items-center gap-8")}>
      <TabGroup<FilePage>
        selectedKey={currentTab}
        tabs={pages.map((pageName) => {
          const disabled = !availableTabs?.includes(pageName);
          return {
            key: pageName,
            title: FILE_PAGE_NAMES[pageName],
            disabled,
            tooltip: disabled
              ? `${FILE_PAGE_NAMES[pageName]} not supported for ${
                  currentLocation?.type === "file"
                    ? capitalizedFileType(getFileTypeFromId(currentLocation.id))
                    : "directory"
                }s`
              : undefined,
            wrapper: pageName === "logs" && showTourtip ? tourtipWrapper : undefined,
            href: currentLocation ? getFileHref({ slug, id: currentLocation?.id, page: pageName }) : undefined,
            onSelect: (newPage: FilePage) => {
              if (currentTab === "logs" && showTourtip) {
                dismiss();
                return;
              }
              if (!disabled) {
                setLastSelectedTab(newPage);
                setCurrentTab(newPage);
              }
            },
          };
        })}
      />
    </div>
  );
};
