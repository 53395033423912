import { useSidebarContext } from "@/context/SidebarContextProvider";
import { updateDirectory, useDirectory } from "@/services/directories.service";
import { updateFile, useFile } from "@/services/files.service";
import { FileType } from "@/types/app/file";
import { SidePanel } from "@components/layouts/SidePanel";
import Button from "@components/library/Button";
import { hlToast, ToastVariant } from "@components/library/Toast";
import { Textarea } from "@components/molecules/FormikInput";
import { FileText, PencilSimple } from "@phosphor-icons/react";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { classNames } from "@/lib/utils";
import { ContextMenuItem } from "@components/library/ContextMenu";
import { SafeMarkdown } from "../SafeMarkdown";

// Base ReadmePanel component that handles the core readme functionality
interface ReadmePanelProps {
  readme: string | null;
  onUpdate: (readme: string | null) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  fileType: FileType | "directory";
}

const ReadmePanel = ({ fileType, readme, onUpdate, isOpen, onClose }: ReadmePanelProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [readmeContent, setReadmeContent] = useState(readme);

  const handleSave = async () => {
    try {
      await onUpdate(readmeContent || null);
      setIsEditing(false);
      hlToast({
        title: "README updated",
        variant: ToastVariant.Success,
      });
    } catch (error) {
      console.error(error);
      hlToast({
        title: "Failed to update README",
        description: "Something went wrong while updating your README",
        variant: ToastVariant.Error,
      });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsEditing(false);
    }
  }, [isOpen]);

  // Update the local state if the readme changes elsewhere
  // (also protection against accidentally setting the state before the file has loaded)
  useEffect(() => {
    setReadmeContent(readme);
  }, [readme]);

  const PLACEHOLDER_TEXT = `Explain the ${capitalize(fileType)}, how to run it and any other comments. You can use **Markdown**.`;
  return (
    <SidePanel
      title={
        <div className="flex items-center gap-12">
          {isEditing ? (
            <div className="flex items-center gap-8">
              <Button size={24} shade={"black"} styling="solid" onClick={handleSave}>
                Save
              </Button>
              <Button size={24} styling="outline" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
            </div>
          ) : (
            <Button IconLeft={PencilSimple} size={24} styling="outline" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          )}
        </div>
      }
      width={500}
      open={isOpen}
      handleClose={onClose}
      content={
        <main className="flex h-full flex-col gap-24">
          <div className="flex grow flex-col gap-12">
            <div className="prose-sm flex grow flex-col">
              {isEditing ? (
                <Textarea
                  name="readme"
                  className="h-full grow font-mono"
                  onSubmit={handleSave}
                  value={readmeContent || ""}
                  placeholder={PLACEHOLDER_TEXT}
                  onChange={(e) => setReadmeContent(e.target.value)}
                />
              ) : (
                <div className={classNames("grow", readme ? "" : "select-none text-text-base-4")}>
                  <SafeMarkdown>{readme || PLACEHOLDER_TEXT}</SafeMarkdown>
                </div>
              )}
              <div className="my-12 text-13-18 text-text-base-3">
                Use this area to explain the purpose of this {capitalize(fileType)}, how to run it, and any other
                comments. You can use Markdown to format your text.
              </div>
            </div>
          </div>
        </main>
      }
    />
  );
};

// File-specific implementation
export const FileReadmeSidePanel = ({ fileId }: { fileId: string }) => {
  const { sidePanelState, setSidePanelState } = useSidebarContext();
  const { file, mutate } = useFile({ fileId });

  const handleUpdate = async (readme: string | null) => {
    if (!readme) return;
    const updatedFile = (await updateFile(fileId, { readme })).data;
    mutate(updatedFile);
  };

  if (!file) return null;
  return (
    <ReadmePanel
      fileType={file.type}
      readme={file.readme}
      onUpdate={handleUpdate}
      isOpen={sidePanelState === "details"}
      onClose={() => setSidePanelState("closed")}
    />
  );
};

// Directory-specific implementation
export const DirectoryReadmeSidePanel = ({ directoryId }: { directoryId: string }) => {
  const { sidePanelState, setSidePanelState } = useSidebarContext();
  const { directory, mutate } = useDirectory(directoryId);

  const handleUpdate = async (readme: string | null) => {
    if (!readme) return;
    const _ = (await updateDirectory(directoryId, { readme })).data;
    mutate(); // Can't give the new directory here as its a different object
  };

  if (!directory) return null;
  return (
    <ReadmePanel
      fileType="directory"
      readme={directory.readme}
      onUpdate={handleUpdate}
      isOpen={sidePanelState === "details"}
      onClose={() => setSidePanelState("closed")}
    />
  );
};

// Shared button component
export const ReadmeButton = ({ fileId, directoryId }: { fileId?: string; directoryId?: string }) => {
  const { setSidePanelState, sidePanelState } = useSidebarContext();

  const { file } = useFile(fileId ? { fileId } : null);
  const { directory } = useDirectory(directoryId && directoryId !== "home" ? directoryId : null);
  const fileOrDirectory = fileId ? file : directory;
  const hasReadme = fileOrDirectory?.readme;

  return (
    <Button
      key="details"
      onClick={() => {
        setSidePanelState((state) => (state !== "details" ? "details" : "closed"));
      }}
      disabled={directoryId === "home"}
      active={sidePanelState === "details"}
      IconLeft={FileText}
      styling="ghost"
      shade={"gray"}
      className={hasReadme ? "opacity-100" : "opacity-75"}
      size={24}
    >
      Readme
    </Button>
  );
};

export const ReadmeContextMenu = ({ fileId, directoryId }: { fileId?: string; directoryId?: string }) => {
  const { setSidePanelState, sidePanelState } = useSidebarContext();

  const { file } = useFile(fileId ? { fileId } : null);
  const { directory } = useDirectory(directoryId && directoryId !== "home" ? directoryId : null);
  const fileOrDirectory = fileId ? file : directory;
  const hasReadme = fileOrDirectory?.readme;

  return (
    <ContextMenuItem
      withinDropdownMenu
      key="details"
      onClick={() => {
        setSidePanelState((state) => (state !== "details" ? "details" : "closed"));
      }}
      disabled={directoryId === "home"}
      active={sidePanelState === "details"}
      IconLeft={{ Icon: FileText }}
      className={hasReadme ? "opacity-100" : "opacity-75"}
    >
      Readme
    </ContextMenuItem>
  );
};
